/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_doctor/layout/core'

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <div className='d-flex bgi-size-cover bgi-position-center order-1 order-lg-2 h-100 dashboard-image'>
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100 h-100'></div>
      </div>
    </>
  )
}

export default DashboardWrapper
